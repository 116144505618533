import type { StripeError } from "@stripe/stripe-js";
import { isObjectWithKey } from "@warrenio/utils/isObject";
import { pick } from "remeda";
import { registerErrorField } from "../../error/errorFields.tsx";

class StripeException extends Error {
    name = "StripeException";

    /** Safe-to-log fields for error reporting */
    info: ReturnType<typeof extractSafeFields>;

    constructor(public readonly stripeError: StripeError) {
        super(getMessage(stripeError));
        this.info = extractSafeFields(stripeError);
    }
}

registerErrorField("info", (x) => isObjectWithKey(x, "type"));

function getMessage(stripeError: StripeError) {
    let { message } = stripeError;
    if (stripeError.code) {
        message = `${message} [${stripeError.code}]`;
    }
    return message;
}

function extractSafeFields(stripeError: StripeError) {
    return pick(stripeError, ["type", "charge", "code", "decline_code", "doc_url", "param", "request_log_url"]);
}

/** Utility function to convert a Stripe error into an thrown exception. Simplifies error handling & rollback. */
export function throwStripeError(error: StripeError | undefined): asserts error is undefined {
    if (error) {
        throw new StripeException(error);
    }
}

import { type PayPalButtonsComponent } from "@paypal/paypal-js";
import type { PaypalVaultConfig } from "@warrenio/api-spec/spec.oats.gen";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useUnmountSignal } from "../../../utils/react/useUnmountSignal.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import { ApiStandardError } from "../../api/ApiError.ts";
import { ErrorMessage } from "../../error/ErrorFallback.tsx";
import type { AddMethodProps } from "../choose_method/AddMethodParams.ts";
import { addCardRequest } from "../choose_method/addMethodUtils.ts";
import { FormBox } from "../choose_method/FormBox.tsx";
import { ErrorText } from "../payment_forms/components.tsx";
import { paypalLoader } from "./paypalLoader.ts";
import * as queries from "./paypalQuery.ts";
import { PayPalVaultLoadException, type ExtendedCardFieldsOnApproveData } from "./utils.ts";

export function PaypalVaultAddForm({
    actionsRef,
    config: { client_id },
}: { config: PaypalVaultConfig } & AddMethodProps) {
    //#region Hooks
    const apiClient = useApiClient();
    const signal = useUnmountSignal();

    const [loadError, setLoadError] = useState<PayPalVaultLoadException | undefined>(undefined);
    const [error, setError] = useState("");
    const paypalButtons = useRef<PayPalButtonsComponent | undefined>(undefined);

    const paypalSessionId = useRef<string | undefined>(undefined);
    const paypalPaymentToken = useRef<string | undefined>(undefined);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (async () => {
            let paypal;
            let sessionId: string | undefined;
            try {
                const loadResult = await paypalLoader({ apiClient, signal, clientId: client_id });
                paypal = loadResult.paypal;
                sessionId = loadResult.sessionId;
            } catch (error) {
                const paypalError = new PayPalVaultLoadException({ cause: error });
                setLoadError(paypalError);
                throw paypalError;
            }

            if (!paypal?.Buttons || sessionId === undefined) {
                setLoadError(new PayPalVaultLoadException());
            } else {
                paypalSessionId.current = sessionId;
                // abort if paypalButtons (paypalButtons.current) is already initiated
                if (paypalButtons.current) return;

                paypalButtons.current = paypal.Buttons({
                    createVaultSetupToken: async () => {
                        try {
                            const result = await queries.setupTokenQuery(apiClient, signal, sessionId);
                            console.debug("PayPal setup token response", result);
                            return result.token;
                        } catch (e: unknown) {
                            const msg = e instanceof ApiStandardError ? e.errorMessage : "Problem with PayPal API.";
                            setError(msg);
                            return "";
                        }
                    },
                    onApprove: async (data) => {
                        setError("");
                        const vaultSetupToken = (data as ExtendedCardFieldsOnApproveData).vaultSetupToken!;
                        try {
                            const result = await queries.paymentTokenQuery(
                                apiClient,
                                signal,
                                sessionId,
                                vaultSetupToken,
                            );
                            console.debug("PayPal payment token response", result);
                            paypalPaymentToken.current = result.token;
                        } catch (e: unknown) {
                            const msg = e instanceof ApiStandardError ? e.errorMessage : "Problem with PayPal API.";
                            setError(msg);
                        }
                    },
                    onError: (error) => {
                        console.log(`Error: ${error.message}`);
                    },
                });

                await paypalButtons.current.render("#paypal-vault-button");
            }
        })();
    }, [client_id, apiClient, signal]);

    useImperativeHandle(actionsRef, () => ({
        // eslint-disable-next-line @typescript-eslint/require-await
        async validate() {
            const isValid = !!paypalPaymentToken.current;
            if (!isValid) {
                setError("Connect to PayPal first");
            }
            return isValid;
        },
        needsPopUp() {
            return false;
        },
        async addPaymentMethod({ account, progress }) {
            progress("Saving PayPal token");
            await addCardRequest(apiClient, {
                billing_account_id: account.id,
                session_id: paypalSessionId.current,
                token: paypalPaymentToken.current!,
            });
        },
    }));
    //#endregion

    return (
        <FormBox>
            <>
                {loadError && <ErrorMessage error={loadError} />}
                {error && (
                    <div className="pb-3">
                        <ErrorText text={error} />
                    </div>
                )}
                <div id="paypal-vault-button"></div>
            </>
        </FormBox>
    );
}

export default PaypalVaultAddForm;

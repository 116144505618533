import type {
    PaypalIdTokenResponse,
    PaypalPaymentTokenResponse,
    PaypalSetupTokenResponse,
} from "@warrenio/api-spec/spec.oats.gen";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";

export async function idTokenQuery(
    apiClient: ApiClient,
    signal: AbortSignal,
    sessionId: string,
): Promise<PaypalIdTokenResponse> {
    return getResponseData(
        await apiClient.GET("/payment/paypal/id_token", {
            params: { query: { session_id: sessionId } },
            signal,
        }),
    );
}

export async function setupTokenQuery(
    apiClient: ApiClient,
    signal: AbortSignal,
    sessionId: string,
): Promise<PaypalSetupTokenResponse> {
    return getResponseData(
        await apiClient.GET("/payment/paypal/setup_token", {
            params: { query: { session_id: sessionId } },
            signal,
        }),
    );
}

export async function paymentTokenQuery(
    apiClient: ApiClient,
    signal: AbortSignal,
    sessionId: string,
    vaultSetupToken: string,
): Promise<PaypalPaymentTokenResponse> {
    return getResponseData(
        await apiClient.GET("/payment/paypal/payment_token", {
            params: { query: { session_id: sessionId, setup_token: vaultSetupToken } },
            signal,
        }),
    );
}

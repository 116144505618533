import type { PaypalVaultConfig } from "@warrenio/api-spec/spec.oats.gen";
import React from "react";
import type { CardMethod } from "../PaymentMethod.tsx";
import { defaults } from "../paymentMethodDefaults.tsx";

/* eslint-disable react-refresh/only-export-components */
const PaypalVaultAddForm = React.lazy(() => import("./PaypalVaultAddForm.tsx"));
const PaypalVaultTopUpForm = React.lazy(() => import("./PaypalVaultTopUpForm.tsx"));
/* eslint-enable react-refresh/only-export-components */

export function makePaypalVault(config: PaypalVaultConfig): CardMethod {
    return {
        ...defaults(config),
        type: "creditcard",
        TopUpForm: (props) => <PaypalVaultTopUpForm {...props} config={config} />,
        AddForm: (props) => <PaypalVaultAddForm {...props} config={config} />,
    };
}

import type { InvoiceConfig, ProcessorConfig } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import React from "react";
import { configAtom } from "../../config.ts";
import { addDevVars } from "../../dev/initDev.tsx";
import { showWarn } from "../error/errorStream.ts";
import { makeClictopayLink } from "./clictopay/makeClictopayLink.tsx";
import { makeDuitkuLink } from "./duitku/makeDuitku.tsx";
import { makeOmiseCard, makeOmiseLink } from "./omise/makeOmise.tsx";
import type { CardMethod, InvoiceMethod, PaymentMethod } from "./PaymentMethod.tsx";
import { defaults } from "./paymentMethodDefaults.tsx";
import { makePaypalVault } from "./paypal/makePaypal.tsx";
import { makeSslcommerzLink } from "./sslcommerz/makeSslcommerz.tsx";
import { makeStripe } from "./stripe/makeStripe.tsx";
import { makeStripeLink } from "./stripe/makeStripeLink.tsx";

function makeInvoice(config: InvoiceConfig): InvoiceMethod {
    return {
        ...defaults(config),
        type: "invoice",
        AddForm: React.lazy(() => import("./choose_method/InvoiceForm.tsx")),
    };
}

function warnUnknownProcessor(config: ProcessorConfig) {
    showWarn(`Unknown payment processor: ${config.type}/${config.keyword}`);
    return undefined;
}

function makeMethod(config: ProcessorConfig): PaymentMethod | PaymentMethod[] | undefined {
    const { type, keyword } = config;
    switch (type) {
        case "creditcard":
            switch (keyword) {
                case "stripe_creditcard":
                    return makeStripe(config);

                case "omise_creditcard":
                    return makeOmiseCard(config);

                case "paypal_vault":
                    return makePaypalVault(config);

                default:
                    return warnUnknownProcessor(config);
            }

        case "invoice":
            return makeInvoice(config);

        case "link":
            switch (keyword) {
                case "duitku":
                    return makeDuitkuLink(config);
                case "sslcommerz":
                    return makeSslcommerzLink(config);
                case "stripe_wallet":
                    return makeStripeLink(config);

                case "clictopay":
                    return makeClictopayLink(config);

                case "omise":
                    return makeOmiseLink(config);

                default:
                    return warnUnknownProcessor(config);
            }

        case "hybrid":
            throw new Error("Hybrid processor is deprecated");

        default:
            return warnUnknownProcessor(config);
    }
}

function buildMethodsList(configs: ProcessorConfig[]) {
    return configs.flatMap((config) => {
        const methods = makeMethod(config);
        return methods ? (Array.isArray(methods) ? methods : [methods]) : [];
    });
}

export const processorsAtom = atom((get) => mapFrom(buildMethodsList(get(configAtom).paymentProcessors), (p) => p.id));

// NB: Currently, only a single credit card processor can be active since the cards do not specify which processor they belong to
// (and the backend only supports one active as well)
export const creditCardProcessorAtom = atom((get) =>
    [...get(processorsAtom).values()].find((p): p is CardMethod => p.type === "creditcard"),
);

addDevVars({ processorsAtom });

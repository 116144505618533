import { type PayPalNamespace } from "@paypal/paypal-js";
import { loadScript } from "../../../utils/loadScript.ts";
import type { ApiClient } from "../../api/apiClient.ts";
import * as queries from "./paypalQuery.ts";
import { getPaypalSessionId } from "./utils.ts";

type SDKComponentsOption = ("buttons" | "messages" | "marks" | "card-fields" | "funding-eligibility")[];
export interface PaypalLoaderProps {
    apiClient: ApiClient;
    signal: AbortSignal;
    clientId: string;
    sdkComponents?: SDKComponentsOption;
}

export async function paypalLoader(
    loaderProps: PaypalLoaderProps,
): Promise<{ paypal: PayPalNamespace | null | undefined; sessionId: string }> {
    // TODO: "paypalSessionId" should be deleted on logout from session storage!
    // Maybe reload window also to remove loaded PayPal JS SDK script?
    const sessionId = getPaypalSessionId();

    if (window.paypal) {
        return { paypal: window.paypal, sessionId };
    }

    const { apiClient, signal, clientId, sdkComponents } = loaderProps;
    const { token: idToken } = await queries.idTokenQuery(apiClient, signal, sessionId);

    const url = new URL("https://www.paypal.com/sdk/js");
    if (sdkComponents?.length) url.searchParams.append("components", sdkComponents.join(","));
    url.searchParams.append("client-id", clientId);

    await loadScript(url.toString(), (s) => {
        if (idToken) s.setAttribute("data-user-id-token", idToken);
    });
    return { paypal: window.paypal, sessionId };
}

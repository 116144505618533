import { useEffect, useRef } from "react";

export class UnmountedError extends Error {
    name = "UnmountedError";
}

/** Aborts with an {@link UnmountedError} when the component is unmounted */
export function useUnmountSignal() {
    const controller = useRef<AbortController>(undefined!);
    controller.current ??= new AbortController();

    useEffect(() => {
        return () => {
            console.debug("Unmount, sending abort signal");
            controller.current.abort(new UnmountedError());

            // Reset the controller for the next render (for eg. when the component is re-mounted or hot-reloaded)
            controller.current = new AbortController();
        };
    }, []);

    return controller.current.signal;
}

/** @see {@link useUnmountSignal} */
export function useUnmountAbort(controller: AbortController) {
    useEffect(() => {
        // Abort on unmount
        return () => {
            console.debug("Unmount, sending abort signal");
            controller.abort(new UnmountedError());
        };
    }, [controller]);
}

import type { OmiseConfig, OmiseCreditCardConfig } from "@warrenio/api-spec/spec.oats.gen";
import React from "react";
import type { CardMethod, LinkMethod } from "../PaymentMethod.tsx";
import { defaults, linkDefaults } from "../paymentMethodDefaults.tsx";

const OmiseTopUpForm = React.lazy(() => import("./OmiseTopUpForm.tsx"));
const OmiseCardAddForm = React.lazy(() => import("./OmiseCardAddForm.tsx"));
const OmiseCardTopUpForm = React.lazy(() => import("./OmiseCardTopUpForm.tsx"));

export function makeOmiseLink(config: OmiseConfig) {
    return config.methods.map(
        (method): LinkMethod => ({
            ...linkDefaults(config, method),
            TopUpForm: (props) => <OmiseTopUpForm {...props} method={method} config={config} />,
        }),
    );
}

export function makeOmiseCard(config: OmiseCreditCardConfig): CardMethod {
    return {
        ...defaults(config),
        type: "creditcard",
        TopUpForm: (props) => <OmiseCardTopUpForm {...props} config={config} />,
        AddForm: (props) => <OmiseCardAddForm {...props} config={config} />,
    };
}

import type { PaypalVaultConfig } from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle } from "react";
import { useApiClient } from "../../api/apiClient.store.ts";
import { TopUpResult, type TopUpProps } from "../topup/TopUpParams.ts";
import { creditBuyRequest } from "../topup/topUpUtils.ts";
import { getPaypalSessionId } from "./utils.ts";

export function PaypalVaultTopUpForm({ actionsRef }: TopUpProps & { config: PaypalVaultConfig }) {
    const apiClient = useApiClient();

    useImperativeHandle(actionsRef, () => ({
        needsPopUp(_params) {
            return false;
        },
        async topUp({ amount, getCard, progress, account }) {
            progress("Sending payment request");
            const buyResult = await creditBuyRequest(apiClient, {
                billing_account_id: account.id,
                payment_object_id: getCard().id,
                amount,
                session_id: getPaypalSessionId(),
                on_session: false,
            });

            if (buyResult.success) {
                return TopUpResult.SUCCESS;
            }

            return TopUpResult.VALIDATION_FAILED;
        },
    }));

    return null;
}

export default PaypalVaultTopUpForm;

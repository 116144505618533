import type { ReactNode } from "react";

interface LabelProps {
    label: ReactNode;
    placeholder?: string;
}

export const cardNumberLabel = {
    label: "Card Number",
    // https://ux.stackexchange.com/questions/106902/credit-card-field-placeholder-text
    // placeholder: "4242 4242 4242 4242",
} as const satisfies LabelProps;

export const cardExpiryLabel = {
    label: "Valid thru",
    placeholder: "MM/YY",
} as const satisfies LabelProps;

export const cardCvcLabel = {
    label: "CVC",
    placeholder: "123",
} as const satisfies LabelProps;
